<template>
  <v-container fluid app>
    <div class="col-md-12 mb-3">
      <page-title
        title="Digital Ecosystem"
        subtitle=" Statistical Information Management System Sub-Activity "
        :useBackBtn="true"
      ></page-title>
    </div>

    <v-row class="mt-2">
      <v-col cols="8" md="8">
        <v-card elevation="1">
          <div class="card">
            <div class="card-body">
              <v-row>
                <div class="table-responsive browser_users col-lg-6">
                  <v-simple-table class="table mb-0">
                    <thead class="thead-light"></thead>
                    <tbody>
                      <tr>
                        <td class="text-title">Project Name (Code):</td>
                        <td class="text-subtitle">Innovation Center</td>
                      </tr>

                      <tr>
                        <td class="text-title">Project Location</td>
                        <td class="text-subtitle">Dar-Makutupola</td>
                      </tr>

                      <tr>
                        <td class="text-title">Employer Name</td>
                        <td class="text-subtitle">GoT</td>
                      </tr>

                      <tr>
                        <td class="text-title">Engineer Name (Emp.Rep)</td>
                        <td class="text-subtitle">YAPI</td>
                      </tr>

                      <tr>
                        <td class="text-title">Contractor Name:</td>
                        <td class="text-subtitle">ARAB Contractor</td>
                      </tr>

                      <tr>
                        <td class="text-title">
                          Contract Duration (in Months)
                        </td>
                        <td class="text-subtitle">36</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>

                <div class="table-responsive browser_users col-lg-6">
                  <v-simple-table class="table mb-0">
                    <tbody>
                      <tr>
                        <td class="text-title">Contract Signature Date</td>
                        <td class="text-subtitle">2016-07-10</td>
                      </tr>
                      <tr>
                        <td class="text-title">Commencement Date</td>
                        <td class="text-subtitle">2017-01-12</td>
                      </tr>
                      <tr>
                        <td class="text-title">Contract competion Date</td>
                        <td class="text-subtitle">2022-09-21</td>
                      </tr>
                      <tr>
                        <td class="text-title">
                          Extension of Time (EOT No.1) Completion date
                        </td>
                        <td class="text-subtitle"></td>
                      </tr>
                      <tr>
                        <td class="text-title">
                          Extension of Time (EOT No.3) Completion Date
                        </td>
                        <td class="text-subtitle"></td>
                      </tr>
                      <tr>
                        <td class="text-title">Type of Finance</td>
                        <td class="text-subtitle"></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col cols="4" md="4">
        <ProjectTranding />
      </v-col>
    </v-row>

    <div class="mt-5">
      <v-card class="col-md-12">
        <div class="d-flex wrap justfy-space-between align-baseline">
          <h3 class="text-dark">Progress Report</h3>
          <div class="d-flex col justify-end">
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  title=""
                  right
                  fab
                  small
                  color="success white--text"
                  style="margin: 5px"
                  ><v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Report Progress</span>
            </v-tooltip>
          </div>
        </div>
        <v-data-table :headers="headers" :items="desserts">


          <template v-slot:item.actions>
            <v-btn link icon><v-icon>mdi-eye</v-icon></v-btn>
          </template>
        </v-data-table>
      </v-card>

      <v-card class="col-md-12 mt-2">
        <div class="d-flex wrap justfy-space-between align-baseline">
          <h3 class="text-dark">Sub-Activity Assignees</h3>
          <div class="d-flex col justify-end">
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  title=""
                  right
                  fab
                  small
                  color="success white--text"
                  style="margin: 5px"
                  ><v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add Assignee</span>
            </v-tooltip>
          </div>
        </div>

        <v-data-table :headers="assignedUsersHeaders" :items="assignedUsers">
          <template v-slot:item.actions="{ item }">
            <v-menu :key="item.name" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  icon
                  color="success"
                  ><v-icon>mdi-dots-vertical</v-icon></v-btn
                >
              </template>
              <v-list dense>
                <v-list-item link>
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-eye</v-icon
                    >
                    View User</v-list-item-title
                  >
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item link to="">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-account-cancel</v-icon
                    >
                    Remove</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
  </v-container>
</template>


<script>
import PageTitle from "../../../digitaltp_shared/page-title.vue";
import ProjectTranding from "../../../me_projects/project_view/ProjectTranding.vue";
export default {
  name: "ProjectSubActivityView",
  components: {
    ProjectTranding,
    PageTitle,
  },
  data() {
    return {
      show: false,
      headers: [
        {
          text: "Title",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Descriptions", value: "fat" },
        { text: "Commulative Expenditure", value: "expenditure" },
        { text: "Actions", value: "actions" },
      ],
      desserts: [
        {
          name: "Statistical Information Management System",
          fat: "Establishment of the National ICT Professional and Innovation Center",
          expenditure: "$ 2.4 Mill",
          actions: "1%",
        },
        {
          name: "e-Commerce",
          fat: "Establishment of the National ICT Professional and Innovation Center",

          expenditure: "$ 1.3 Mill",
          actions: "8%",
        },
        {
          name: "ICT Regulatory",
          fat: "Establishment of the National ICT Professional and Innovation Center",

          expenditure: "$ 0.9 Mill",
          actions: "16%",
        },
      ],

      assignedUsersHeaders: [
        {
          text: "Fullname",
          align: "start",
          sortable: true,
          value: "name",
        },
        { text: "Designation", value: "designation" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Assignment Date", value: "assignedDate" },
        { text: "Status", value: "status" },
        { text: "Action", value: "actions" },
      ],

      assignedUsers: [
        {
          name: "Majaliwa Mtani Moshi",
          designation: "Project Manager",
          email: "majaliwa.moshi@__go.tz",
          phone: "+255788990011",
          assignedDate: "10 Feb, 2022",
          status: "ACTIVE",
        },
        {
          name: "Neema Zakayo Tumaini",
          designation: "ICTO",
          email: "meema.tumaini@__go.tz",
          phone: "+255788990011",
          assignedDate: "10 Feb, 2022",
          status: "ACTIVE",
        },
        {
          name: "John Lusemi",
          designation: "Project Manager",
          email: "john.lusemi@__go.tz",
          phone: "+255788990011",
          assignedDate: "10 Feb, 2022",
          status: "INACTIVE",
        },
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
  methods: {
    getColor(calories) {
      if (calories < 50) return "red";
      else if (calories > 50 && calories < 70) return "orange";
      else return "green";
    },
    getColorStatus(data) {
      if (data == "INACTIVE") return "red";
      else return "green";
    },
  },
};
</script>

<template>
  <v-container fluid app>
    <div class="col-md-12 mb-3">
      <page-title
        title="Digital Ecosystem"
        subtitle=" Innovation Center"
        :useBackBtn="true"
      ></page-title>
    </div>

    <v-row>
      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="success"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4 ylw">
                Project Activities
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                3
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="success"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4">
                Completed Activities
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                1
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="success"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4">
                Ongoing Activities
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                2
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
      <v-col md="3" cols="12">
        <v-card
          link
          to=""
          class="mx-auto"
          max-width="100%"
          outlined
          color="success"
          elevation="1"
        >
          <v-list-item three-line>
            <v-list-item-content>
              <div style="color: white" class="text-overline mb-4">
                Pending Activities
              </div>
              <v-list-item-title
                style="color: white; font-weight: bold"
                class="text-h5 mb-1"
              >
                0
              </v-list-item-title>
              <v-list-item-subtitle style="color: white">
                <span class="text-">
                  <v-icon>mdi-up</v-icon>
                  19,685,900,000,000 TZS
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="9" md="9">
        <v-card elevation="1">
          <div class="card">
            <div class="card-body">
              <h3 class="header-title text-dark mt-0 mb-3 pa-3">
                Contractual Summary
              </h3>
              <v-row>
                <div class="table-responsive browser_users col-lg-6">
                  <v-simple-table class="table mb-0">
                    <thead class="thead-light"></thead>
                    <tbody>
                      <tr>
                        <td class="text-title">Project Name (Code):</td>
                        <td class="text-subtitle">Innovation Center</td>
                      </tr>

                      <tr>
                        <td class="text-title">Project Location</td>
                        <td class="text-subtitle">Dar es Salaam</td>
                      </tr>

                      <tr>
                        <td class="text-title">Employer Name</td>
                        <td class="text-subtitle">GoT</td>
                      </tr>

                      <tr>
                        <td class="text-title">Contractor Name:</td>
                        <td class="text-subtitle">ARAB Contractor</td>
                      </tr>

                      <tr>
                        <td class="text-title">
                          Contract Duration (in Months)
                        </td>
                        <td class="text-subtitle">36</td>
                      </tr>
                      <tr>
                        <td class="text-title">
                          Lead Institution
                        </td>
                        <td class="text-subtitle"> TCRA</td>
                      </tr>

                      <tr>
                        <td class="text-title">
                          Supporting Institutions
                        </td>
                        <td class="text-subtitle">MCIT, eGA, COSTECH</td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>

                <div class="table-responsive browser_users col-lg-6">
                  <v-simple-table class="table mb-0">
                    <tbody>
                      <tr>
                        <td class="text-title">Contract Signature Date</td>
                        <td class="text-subtitle">2016-07-10</td>
                      </tr>
                      <tr>
                        <td class="text-title">Commencement Date</td>
                        <td class="text-subtitle">2017-01-12</td>
                      </tr>
                      <tr>
                        <td class="text-title">Contract competion Date</td>
                        <td class="text-subtitle">2022-09-21</td>
                      </tr>
                      <tr>
                        <td class="text-title">
                          Extension of Time (EOT No.1) Completion date
                        </td>
                        <td class="text-subtitle"></td>
                      </tr>
                      <tr>
                        <td class="text-title">
                          Extension of Time (EOT No.3) Completion Date
                        </td>
                        <td class="text-subtitle"></td>
                      </tr>
                      <tr>
                        <td class="text-title">Type of Finance</td>
                        <td class="text-subtitle"></td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </div>
              </v-row>
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col cols="3" md="3">
        <template>
          <v-card
            max-width="450"
            class="mx-auto"
          >
            <v-toolbar
              color="success"
              dark
              flat
              dense
            >

              <v-toolbar-title>Project Team</v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn icon>
                <v-icon>mdi-account-group-outline</v-icon>
              </v-btn>
            </v-toolbar>

            <v-list three-line>
              <template v-for="(item, index) in items">
                <v-subheader
                  v-if="item.header"
                  :key="item.header"
                  v-text="item.header"
                ></v-subheader>

                <v-divider
                  v-else-if="item.divider"
                  :key="index"
                  :inset="item.inset"
                ></v-divider>

                <v-list-item
                  v-else
                  :key="item.title"
                >
                  <v-list-item-avatar>
                    <v-img :src="item.avatar"></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title v-html="item.title"></v-list-item-title>
                    <v-list-item-subtitle v-html="item.subtitle"></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card>
        </template>
      </v-col>
    </v-row>

    <div class="mt-5 col-md-12">
      <v-card flat class="col-md-12">
        <div class="d-flex wrap justfy-space-between align-baseline">
          <h3 class="text-dark">Project Activities</h3>
          <div class="d-flex col justify-end">
            <v-tooltip bottom color="success">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  title=""
                  right
                  fab
                  small
                  color="success white--text"
                  style="margin: 5px"
                  ><v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>New Activity</span>
            </v-tooltip>
          </div>
        </div>
        <v-data-table :headers="headers" :items="desserts">
          <template v-slot:item.calories="{ item }">
            <v-chip :color="getColor(item.calories)" dark>
              {{ item.calories }}%
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-menu :key="item.name" offset-y app>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  slot="activator"
                  icon
                  color="success"
                  ><v-icon>mdi-dots-vertical</v-icon></v-btn
                >
              </template>
              <v-list dense>
                <v-list-item link to="/components/project_sub_activity">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-eye</v-icon
                    >
                    View Sub-Activities</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="Asignmentdialog=true" >
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-account-eye</v-icon
                    >
                    Assign Reporter</v-list-item-title
                  >
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item link to="">
                  <v-list-item-title
                    ><v-icon style="height: 16px; font-size: 16px"
                      >mdi-delete</v-icon
                    >
                    Delete</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <div class="row">
      <div class="col-md-4">
        <v-card elevation="1">
          <v-system-bar lights-out>On Site Evidence</v-system-bar>
          <v-carousel
            cycle
            height="400"
            hide-delimiter-background
            show-arrows-on-hover
          >
            <v-carousel-item v-for="(slide, i) in slides" :key="i">
              <v-card :loading="loading">
                <template slot="progress">
                  <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                  ></v-progress-linear>
                </template>

                <v-img
                  height="250"
                  src="https://image.architonic.com/prj2-3/20060856/tetsuo-kobori-architects-nicca-innovation-center-architonic-nic-203-01-arcit18.jpg"
                ></v-img>

                <v-card-subtitle>Bado haijakamilika</v-card-subtitle>

                <v-card-text>
                  <v-row align="center" class="mx-0">
                    <div class="grey--text ms-0">DateDec. 12, 2020</div>
                  </v-row>

                  <div class="my-4 text-subtitle-1">
                    Location: [ Lat: -6.2343722, Long: 35.8278254 ]
                  </div>
                </v-card-text>

                <v-divider class="mx-4"></v-divider>
              </v-card>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </div>
      <div class="col-md-8">
        <ProjectTranding />
      </div>
    </div>
    <asignment-form :Asignmentdialog="Asignmentdialog"></asignment-form>
  </v-container>
</template>

<script>
import PageTitle from "../../../digitaltp_shared/page-title.vue";
import ProjectTranding from "../../../me_projects/project_view/ProjectTranding.vue";
import AsignmentForm from "./AsignmentForm.vue";
export default {
  components: {
    ProjectTranding,
    PageTitle,
    AsignmentForm
  },
  data() {
    return {
      show: false,
      Asignmentdialog:false,
      items: [
        { header: 'Permanent Involved in the Project' },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg',
          title: 'James Ilomo',
          subtitle: `<span class="text--primary">Position</span> &mdash; Project Leader`,
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg',
          title: '<span class="grey--text text--lighten-1">Dr.</span> Jaha Mvulla',
          subtitle: `<span class="text--primary">Position</span> &mdash; Technicsl Advisor`,
        },
        { divider: true, inset: true },
        {
          avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg',
          title: 'Isaka Makuyu',
          subtitle: '<span class="text--primary">Position</span> &mdash; Project Financial Representative',
        },
      ],
      headers: [
        {
          text: "Activity Title",
          align: "start",
          sortable: false,
          value: "name",
        },
        { text: "Descriptions", value: "fat" },
        { text: "Budget", value: "carbs" },
        { text: "Disbused Amount", value: "protein" },
        { text: "Commulative Expenditure", value: "expenditure" },
        { text: "Completion in (%)", value: "calories" },
        { text: "Actions", value: "actions" },
      ],
      desserts: [
        {
          name: "Statistical Information Management System",
          calories: 54,
          fat: "Establishment of the National ICT Professional and Innovation Center",
          carbs: "$ 6.7 Mill",
          protein: "$ 4.3 Mill",
          expenditure: "$ 2.4 Mill",
          actions: "1%",
        },
        {
          name: "e-Commerce",
          calories: 100,
          fat: "Establishment of the National ICT Professional and Innovation Center",
          carbs: "5.6",
          protein: "$ 4.3 Mill",
          expenditure: "$ 1.3 Mill",
          actions: "8%",
        },
        {
          name: "ICT Regulatory",
          calories: 26,
          fat: "Establishment of the National ICT Professional and Innovation Center",
          carbs: "$ 4.8 Mill",
          protein: "$ 3.9 Mill",
          expenditure: "$ 0.9 Mill",
          actions: "16%",
        },
      ],
      colors: [
        "indigo",
        "warning",
        "pink darken-2",
        "red lighten-1",
        "deep-purple accent-4",
      ],
      slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    };
  },
  methods: {
    getColor(calories) {
      if (calories < 50) return "red";
      else if (calories > 50 && calories < 70) return "orange";
      else return "green";
    },
  },
};
</script>
